import React from 'react'

import Layout from '../components/layout'
import AboutOne from '../components/about-us/AboutOne'
import AboutThree from '../components/about-us/AboutThree'
import AboutTwo from '../components/about-us/AboutTwo'
import AboutFour from '../components/about-us/AboutFour'

const Work = props => (
  <Layout lang="pt" title="Sobre Nós" meta={{description: 'Um parceiro tecnológico que se reinventa constantemente para agregar valor aos negócios dos seus clientes.'}} rootProps={{ className: 'page about-us' }}>
    <AboutOne lang="pt" />
    <AboutTwo lang="pt" />
    <AboutThree lang="pt" />
    <AboutFour lang="pt" />
  </Layout>
)

export default Work
